import { Component, OnInit, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { DataService } from '../base/data.service';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss']
})

export class ContactFormComponent implements OnInit {

  public contactSuccess: boolean = false;
  public msg: string = '';
  public sending: boolean = false;
  
  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    public location: Location,
    public router: Router,
    private dataService: DataService,
    ) {

  }

  ngOnInit() {}

  validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
    } else {
        return false;
    }
  }

  contactSubmit(form: NgForm) {
    this.sending = true;
    this.msg = '';

    const url = this.config.apiEndpoint + 'hubspot/create_contact_and_or_task.php';

    if (this.validateEmail(form.value.email)) {
        const url_source = this.location.prepareExternalUrl(this.location.path());
      
        const values = { 
            first: form.value.first, 
            last: form.value.last, 
            email: form.value.email,
            phone: form.value.phone,
            state: form.value.state,
            url_source: url_source,
            task_desc: form.value.first + ' ' +  form.value.last + ' filled out the "Contact Form" on our 401k appointment setting landing page from ' + url_source + '. Please reach out. Thank You.'
        };
     
        this.dataService.post(url, values)
            .then(data => {
                if (data && data.engagement && data.engagement.id) {
                    this.contactSuccess = true;
                    this.router.navigate(['/home'],{ queryParams: { email: values.email, formSubmit: true } })
                } else if (data && data.status === 'error') {
                    this.contactSuccess = false;
                    if (data.validationMsg) {
                      this.msg = data.validationMsg;
                    } else {
                      this.msg = 'Form submission failed. Please contact us at ' + this.config.phone
                    }
                }
                
                this.sending = false;
            });
    } else {
        this.msg = "Valid email address required.";
        this.contactSuccess = false;
        this.sending = false;
    }
  }
}
