import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ContactFormModule } from '../contact-form/contact-form.module';
import { RouterModule } from '@angular/router';
import { LearnMoreComponent } from './learn-more.component';

@NgModule({
    imports: [
      CommonModule,
      BrowserModule,
      FormsModule,
      RouterModule,
      ContactFormModule
    ],
    declarations: [ LearnMoreComponent],
    exports:[ LearnMoreComponent ],
    providers: []
})
export class LearnMoreModule { }
