import { Component, OnInit, Inject } from '@angular/core';
import { GoogleAnalyticsEventsService } from '../base/google-analytics-events.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { APP_CONFIG, AppConfig } from 'app/app-config.module';
import { DataService } from 'app/base/data.service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-learn-more',
    templateUrl: './learn-more.component.html',
    styleUrls: ['./learn-more.component.scss']
})

export class LearnMoreComponent implements OnInit {

  public ip: string = '';
  public formSuccess: boolean = false;
  public msg: string = '';
  public sending: boolean = false;

  constructor(
    public router: Router,
    @Inject(APP_CONFIG) public config: AppConfig,
    private dataService: DataService,
    public location: Location,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService
  ) {
    
  }

  ngOnInit() {
    this.dataService.returnIpAddress()
      .then(data => {
          if (data && data.ip) {
            this.ip = data.ip;
            console.log(this.ip);
          }
      });
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');

        let _hsq = (<any>window)._hsq = (<any>window)._hsq || [];
        _hsq.push(['setPath', '/subscribe']);
        _hsq.push(['trackPageView']);
      }
    });
  }

  validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
    } else {
        return false;
    }
  }
        
  adwordsSubmit(form: NgForm) {
    this.sending = true;
    this.msg = '';

    const url = this.config.apiEndpoint + 'hubspot/create_contact_and_or_task.php';

    if (this.validateEmail(form.value.email)) {
        const url_source = this.location.prepareExternalUrl(this.location.path());
      
        const values = { 
            first: form.value.first, 
            last: form.value.last, 
            email: form.value.email,
            phone: form.value.phone,
            state: form.value.state,
            url_source: url_source,
            original_source: 'PAID_SEARCH',
            task_desc: form.value.first + ' ' +  form.value.last + ' filled out our "Learn More" 401k appointment setting web form. Please reach out. Thank You.'
        };
     
        this.dataService.post(url, values)
            .then(data => {
                if (data && data.engagement && data.engagement.id) {
                    this.formSuccess = true;
                    this.router.navigate(['/learn-more-complete'],{ queryParams: { email: values.email, first: values.first } })
                } else if (data && data.status === 'error') {
                    this.formSuccess = false;
                    if (data.validationMsg) {
                      this.msg = data.validationMsg;
                    } else {
                      this.msg = 'Form submission failed. Please contact us at ' + this.config.phone
                    }
                }
                
                this.sending = false;
            });
    } else {
        this.msg = "Valid email address required.";
        this.formSuccess = false;
        this.sending = false;
    }
  }

}
