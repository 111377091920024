import { Component, OnInit, Inject } from '@angular/core';
import { GoogleAnalyticsEventsService } from '../base/google-analytics-events.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { APP_CONFIG, AppConfig } from 'app/app-config.module';
import { Location } from '@angular/common';

@Component({
    selector: 'app-learn-more-complete',
    templateUrl: './learn-more-complete.component.html',
    styleUrls: ['./learn-more-complete.component.scss']
})

export class LearnMoreCompleteComponent implements OnInit {

  public email: string = '';
  public first: string = '';

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(APP_CONFIG) public config: AppConfig,
    public location: Location,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService
  ) {
    
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
        if (params['email'] && params['email'] !== '') {
          this.email = params['email'];
        }

        if (params['first'] && params['first'] !== '') {
          this.first = params['first'];
          console.log(this.first);
        }
    });
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
        (<any>window).gtag('event', 'conversion', {'send_to': 'AW-1005089563/NlKgCPfWyZcBEJvmod8D'}); 

        let _hsq = (<any>window)._hsq = (<any>window)._hsq || [];
        _hsq.push(['setPath', '/subscribe']);
        _hsq.push(['trackPageView']);
      }
    });

    setTimeout(() => {
      this.router.navigate(['/home'],{ queryParams: { email: this.email, first: this.first } })
    }, 3000);
  }

 
}
