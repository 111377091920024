import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsEventsService } from '../base/google-analytics-events.service';
import { Router, NavigationEnd } from '@angular/router';
import { AuthenticationService } from 'app/base/authentication.service';
import { Visitor } from 'app/models/visitor';


@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
})

export class VideoComponent implements OnInit {

  public visitor: Visitor = null;
  
  constructor(
    public router: Router,
    public authenticationService: AuthenticationService,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService
  ) {
    
  }

  ngOnInit() {
    if (this.authenticationService.currentVisitorValue) {
      this.visitor = this.authenticationService.currentVisitorValue;
      console.log(this.visitor);
    }
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');

        let _hsq = (<any>window)._hsq = (<any>window)._hsq || [];
        _hsq.push(['setPath', '/video']);
        _hsq.push(['trackPageView']);
      }
    });
  }

}
