import { Component, OnInit, Inject } from '@angular/core';
import { GoogleAnalyticsEventsService } from '../base/google-analytics-events.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { APP_CONFIG, AppConfig } from 'app/app-config.module';

@Component({
    selector: 'app-subscribe',
    templateUrl: './subscribe.component.html',
    styleUrls: ['./subscribe.component.scss']
})

export class SubscribeComponent implements OnInit {

  public formId: string = '21';
  public formSubmit: boolean = false;
  public formSuccess: boolean = false;
  public errMsg: string = '';

  constructor(
    public router: Router,
    @Inject(APP_CONFIG) public config: AppConfig,
    private activatedRoute: ActivatedRoute,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService
  ) {
    
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
        const success = params['success'];        
        if (success === 'true') {
          this.formSubmit = true;
          this.formSuccess = true;
        } else if (success === 'false') {
          this.formSubmit = true;
          const errors = params['Errors'];
          if (errors) {
            this.errMsg = errors;
          } 
        }
      });
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');

        let _hsq = (<any>window)._hsq = (<any>window)._hsq || [];
        _hsq.push(['setPath', '/subscribe']);
        _hsq.push(['trackPageView']);
      }
    });
  }

}
