import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { VideoComponent } from './video/video.component';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { LearnMoreComponent } from './learn-more/learn-more.component';
import { LearnMoreCompleteComponent } from './learn-more-complete/learn-more-complete.component';

const routes: Routes = [
    { path: 'home', component: HomeComponent },
    { path: 'calculator', component: CalculatorComponent },
    { path: 'video', component: VideoComponent },
    { path: 'subscribe', component: SubscribeComponent },
    { path: 'learn-more', component: LearnMoreComponent },
    { path: 'learn-more-complete', component: LearnMoreCompleteComponent },
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
