import { Component, OnInit, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../../app-config.module';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    constructor(
        public location: Location,
        @Inject(APP_CONFIG) public config: AppConfig
    ) {

    }

    ngOnInit() {

    }

    showLogo() {
        const title = this.location.prepareExternalUrl(this.location.path());

        if (
            title === '/learn-more' ||
            title.includes('/learn-more') || 
            title === '/learn-more-complete' ||
            title.includes('/learn-more-complete')
        ) {
            return false;
        } else {
            return true;
        }
    }
}
