import { NgModule, InjectionToken } from '@angular/core';
//import { environment } from '../environments/environment';
import { environment } from '../environments/environment.prod';

export let APP_CONFIG = new InjectionToken<AppConfig>('app.config');

export class AppConfig {
  apiEndpoint: string;
  companyName: string;
  phoneUrl: string;
  phone: string;
  email: string;
  logo: string;
  websiteUrl: string;
  website: string;
  subscribeURl: string;
}

export const APP_DI_CONFIG: AppConfig = {
  apiEndpoint: environment.apiEndpoint,
  companyName: 'T3 Direct Marketing',
  phoneUrl: '8448462290',
  phone: '(844) 846-2290',
  email: '401kappointments@t3direct.com',
  logo: 'assets/img/logo.png',
  websiteUrl: 'https://www.t3direct.com',
  website: 't3direct.com',
  subscribeURl: 'https://clientdirectservices.com/maillers/form.php?form='
};

@NgModule({
  providers: [{
    provide: APP_CONFIG,
    useValue: APP_DI_CONFIG
  }]
})
export class AppConfigModule { }