import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { AppConfigModule } from './app-config.module';
import { AppComponent } from './app.component';
import { ChartModule } from 'angular2-chartjs';
import { GoogleAnalyticsEventsService } from './base/google-analytics-events.service';
import { HttpClientModule } from '@angular/common/http';

import { DataService } from './base/data.service';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';

import { HomeModule } from './home/home.module';
import { VideoModule } from './video/video.module';
import { CalculatorModule } from './calculator/calculator.module';
import { SubscribeModule } from './subscribe/subscribe.module';
import { LearnMoreModule } from './learn-more/learn-more.module';
import { LearnMoreCompleteModule } from './learn-more-complete/learn-more-complete.module';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    NgbModule.forRoot(),
    FormsModule,
    RouterModule,
    AppRoutingModule,
    HomeModule,
    CalculatorModule,
    VideoModule,
    SubscribeModule,
    LearnMoreModule,
    LearnMoreCompleteModule,
    ChartModule,
    AppConfigModule,
    HttpClientModule
  ],
  providers: [DataService, GoogleAnalyticsEventsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
