import { Component, OnInit, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { DataService } from '../base/data.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsEventsService } from 'app/base/google-analytics-events.service';
import { Visitor } from '../models/visitor';
import { AuthenticationService } from 'app/base/authentication.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

    public visitor: Visitor = null;
    public formSubmit: boolean = false;
    public first: string = '';
    
    constructor(
        @Inject(APP_CONFIG) public config: AppConfig, 
        private dataService: DataService,
        private activatedRoute: ActivatedRoute,
        public router: Router,
        public authenticationService: AuthenticationService, 
        public googleAnalyticsEventsService: GoogleAnalyticsEventsService
    ) { 
        
    }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
            if (params['first']) {
                this.first = params['first'];
            }
            
            if (params['email'] && params['email'] !== '') {
                this.getHubspotContact(params['email']);
            } else {
                if (this.authenticationService.currentVisitorValue) {
                    this.visitor = this.authenticationService.currentVisitorValue;
                    console.log(this.visitor);
                }
            }
        });
    }

    ngAfterViewInit(): void {
        this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            (<any>window).ga('set', 'page', event.urlAfterRedirects);
            (<any>window).ga('send', 'pageview');

            let _hsq = (<any>window)._hsq = (<any>window)._hsq || [];
            _hsq.push(['setPath', '/home']);
            _hsq.push(['trackPageView']);
          }
        });
    }

    getHubspotContact(email) {
        const url = this.config.apiEndpoint + 'hubspot/get_contact_by_email.php';
        const values = {email: email};
        console.log(url);
        
        this.dataService.post(url, values)
            .then(data => {
                if (data) {
                    if (data.vid) {
                        console.log(data);
                        this.authenticationService.setCurrentVisitorValue({vid: data.vid, email: data.properties.email.value});
                        this.visitor = this.authenticationService.currentVisitorValue;
                        console.log(this.visitor);
                    } else if (data.status === 'error' && data.message === "contact does not exist") {
                        console.log(data.message);
                    }
                }
            });
    }

}
