import { Component, OnInit, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../../app-config.module';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    test : Date = new Date();

    constructor(@Inject(APP_CONFIG) public config: AppConfig) { }

    ngOnInit() {}
}
