import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DataService {
    
  constructor(
    private http: HttpClient
  ) {

  }

  returnIpAddress(): Promise<any> {
    return new Promise(resolve => {
      return this.http.get('https://api.ipify.org/?format=json')
      .subscribe(data => {
        resolve(data);
      });
    });
  }

  get(url): Promise<any> {
    return new Promise(resolve => {
      return this.http.get(url)
      .subscribe(data => {
        resolve(data);
      });
    });
  }

  post(url, values): Promise<any> {
    return new Promise(resolve => {
      return this.http.post<any>(url, values, {headers : {
          'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
      }})
      .subscribe(data => {
        resolve(data);
      });
    });
  }
  
}
