import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { GoogleAnalyticsEventsService } from 'app/base/google-analytics-events.service';
import { AuthenticationService } from 'app/base/authentication.service';
import { Visitor } from 'app/models/visitor';

@Component({
    selector: 'app-calculator',
    templateUrl: './calculator.component.html',
    styleUrls: ['./calculator.component.scss']
})

export class CalculatorComponent implements OnInit {
  focus: any;
  focus1: any;

  public estimated_appt_prices: any = {
    financial: 525
  };

  public monthly_budget: number = null;
  public percent_meetings_convert_to_sales: number = null;
  public meetings_per_month: number = null;
  public closed_deals: number = null;
  public avg_cycle_in_months: number = null;
  public avg_deal_size: number = null;
  public revenue: number = null;
  public margin: number = null;

  type = 'line';
  
  data = {
    labels: [
      "Month 1", 
      "Month 2", 
      "Month 3", 
      "Month 4", 
      "Month 5", 
      "Month 6", 
      "Month 7", 
      "Month 8", 
      "Month 9", 
      "Month 10", 
      "Month 11", 
      "Month 12"
    ],
    datasets: [
      {
        label: "Margin Projections 12 Month",
        data: []
      }
    ]
  };
  
  options = {
    responsive: true,
    maintainAspectRatio: false
  };

  public visitor: Visitor = null;

  constructor(
    public router: Router,
    public authenticationService: AuthenticationService,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService
  ) {

  }
  
  ngOnInit() {
    if (this.authenticationService.currentVisitorValue) {
      this.visitor = this.authenticationService.currentVisitorValue;
      console.log(this.visitor);
    }
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');

        let _hsq = (<any>window)._hsq = (<any>window)._hsq || [];
        _hsq.push(['setPath', '/calculator']);
        _hsq.push(['trackPageView']);
      }
    });
  }

  getMeetingsPerMonth() {
    this.meetings_per_month = Math.floor(this.monthly_budget / this.estimated_appt_prices.financial);
    return this.meetings_per_month;
  }

  getClosedDeals() {
    this.closed_deals = Math.floor(this.meetings_per_month * (this.percent_meetings_convert_to_sales / 100));
    return this.closed_deals;
  }

  getRevenue() {
    this.revenue = this.closed_deals * this.avg_deal_size;
    return this.revenue;
  }

  getMargin() {
    if (this.revenue && this.revenue > 0) {
      this.margin = this.revenue - this.monthly_budget;
    }
    return this.margin;
  }
  
  getPcc(amount) {
    let sum = 0;
    for (let i = 0; i < 12; i++) { 
      sum += amount * (i+1);
    }
    return sum;
  }

  getClosedDealsThisMonth(month) {
    if (month < this.avg_cycle_in_months) {
      return 0;
    } else {
      return this.closed_deals;
    }
  }

  getPositions() {
    let months = [];
    
    for (let i = 0; i < 12; i++) { 
      if ((i+1) >= this.avg_cycle_in_months) {
        months.push(i+1);
      }
    }

    return months;
  }

  getTotalRevenue(amount, month) {
    let val = 0;

    if (amount && amount > 0) {
      let position = null;
      let positions = this.getPositions();
      let index = positions.indexOf(month);

      if (index > -1) {
        position = index + 1;
        val = amount * position;
      }
    }
    return val;
  }

  getTotalRevenueSum(amount) {
    let sum = 0;

    if (amount && amount > 0) {
      let positions = this.getPositions();
      for (let i = 0; i < positions.length; i++) {
        sum += amount * (i + 1);
      }
    }

    return sum;
  }

}
