import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { Visitor } from '../models/visitor';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentVisitorSubject: BehaviorSubject<Visitor>;
    public currentVisitor: Observable<Visitor>;

    constructor(
      @Inject(APP_CONFIG) private config: AppConfig
    ) {
        this.currentVisitorSubject = new BehaviorSubject<Visitor>(JSON.parse(localStorage.getItem('currentVisitor')));
        this.currentVisitor = this.currentVisitorSubject.asObservable();
    }

    public get currentVisitorValue(): Visitor {
      return this.currentVisitorSubject.value;
    }

    public setCurrentVisitorValue(visitor: Visitor): void {
      localStorage.setItem('currentVisitor', JSON.stringify(visitor));
      this.currentVisitorSubject.next(visitor);
    }

    public clearCurrentVisitorValue(visitor): void {
      localStorage.removeItem('currentVisitor');
      this.currentVisitorSubject.next(null);
    }
}